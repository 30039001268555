import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FiMessageSquare } from "react-icons/fi";

const LandingPage: React.FC = () => {
  const [slug, setSlug] = useState<string | null>(null);

  useEffect(() => {
    const trackVisit = async () => {
      try {
        const response = await axios.post("https://codeoceantech.pythonanywhere.com/api/track/", {
          video_played: true,
        });
        setSlug(response.data.slug);
      } catch (error) {
        console.error("Error tracking visit:", error);
      }
    };

    const updateDuration = async () => {
      if (slug) {
        try {
          await axios.post(`https://codeoceantech.pythonanywhere.com/api/update-duration/${slug}/`);
        } catch (error) {
          console.error("Error updating visit duration:", error);
        }
      }
    };

    trackVisit();

    window.addEventListener("beforeunload", updateDuration);

    return () => {
      window.removeEventListener("beforeunload", updateDuration);
    };
  }, [slug]);

  return (
    <div className="relative w-full h-screen bg-gradient-to-tl from-[#0b1d26] to-[#0b1d26] flex flex-col justify-around items-center py-8">
      {/* Message Above Video */}
      <div className="max-w-md w-full text-center mb-6">
        <h1 className="text-white text-3xl font-semibold leading-tight">
          "🚪✨ كيف يمكن للبرمجة أن تفتح أبوابًا جديدة لمستقبلك ومستقبل أطفالك؟ 🤔💡
        </h1>
      </div>

      {/* YouTube Video Embed */}
      <div className="w-[90%] max-w-[1200px] h-full max-sm:h-72 mb-2">
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/R41P7fM5iwo"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* WhatsApp Button */}
      <a
        href="https://wa.me/201115867603"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#fbd784] text-black flex items-center justify-center px-4 py-2 rounded-lg hover:bg-[#fbd788] transition"
      >
        <div className="flex items-center">
          <span className=" text-lg font-[Plaster] font-bold text-black">تواصل معنا عبر الواتساب</span>
          <FiMessageSquare className="ml-2 text-black" />
        </div>
      </a>
    </div>
  );
};

export default LandingPage;
